<template>
  <Toolbar class="padding-x">
    <template #left>
      <img @click="goToLogin" class="header-toolbar cursor-pointer" src="@/assets/bridgit.svg" />
    </template>

    <template #right>
      <div class="header-toolbar flex">
        <img src="@/assets/icons/phone.svg" class="p-mr-3" />
        <!-- <img class="ml-2" src="@/assets/icons/phone-no.svg" alt="" /> -->
        <div class="header-info">
          <p class="phone-info">
            <a href="'tel:+1300141161'" target="_blank">1300 141 161</a>
          </p>
          <!--href="'tel:+1300141161'" -->
          <span class="week-info">Weekdays 9am - 5:30pm</span>
        </div>
      </div>
    </template>
  </Toolbar>
</template>

<script>
import Toolbar from 'primevue/toolbar';

export default {
  name: 'Header',
  components: {
    Toolbar,
  },
  data() {
    return {};
  },
  methods: {
    goToLogin() {
      window.open('https://www.bridgit.com.au/');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}

.padding-x {
  padding: 0 2rem;
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-width: 425px) {
  .header-toolbar {
    width: 130px;
  }
}

.phone-info {
  margin: 0px;
  font-size: 24px;
  color: $secondary-color;
  line-height: 27px;
  a {
    color: $secondary-color !important;
    cursor: pointer;
  }
}
.week-info {
  font-size: 11px;
  color: $secondary-color;
  position: absolute;
}
@media (max-width: 480px) {
  .phone-info a {
    cursor: pointer;
    color: $secondary-color !important;
    pointer-events: auto;
  }
  .phone-info {
    font-size: 14px;
  }
  .week-info {
    font-size: 9px;
    display: none;
  }
  .header-info {
    display: flex;
    align-items: center;
  }
}
</style>
