<template>
  <Card>
    <template #title>
      <div class="hk-grotest">Loan status</div>
    </template>
    <template
      #content
      v-if="
        dashboardLoan?.applicationStatus !== 'cancelled' &&
        dashboardLoan?.loanStatus !== 'dropped_off' &&
        dashboardLoan?.loanStatus !== 'withdrawn' &&
        dashboardLoan?.loanStatus !== 'rejected' &&
        dashboardLoan?.loanStatus !== 'on_hold'
      "
    >
      <div class="dashboard-container">
        <div v-if="dashboardLoan.statusText">
          <div class="position-relative">
            <circle-progress
              :percent="dashboardLoan.percentage ? dashboardLoan.percentage : 0"
              :border-width="10"
              :border-bg-width="10"
              fill-color="#2CDD80"
              :size="190"
            >
            </circle-progress>
            <div
              class="position-absolute completed-percentage"
              :class="dashboardLoan.percentage == 100 ? 'completed-100' : ''"
            >
              <span class="hk-grotest-bold pm-txt-color text"> {{ dashboardLoan.percentage }}% </span>
              <div
                class="completed-value hk-grotest lt-txt-color"
                :class="dashboardLoan.percentage == 100 ? 'ml-4' : 'ml-3'"
              >
                Completed
              </div>
            </div>
          </div>
        </div>
        <div v-if="dashboardLoan.statusText" class="dashboard-current-application">
          <div class="font-size-1 pm-txt-color">Current application progress</div>
          <h3 class="mt-3 support heading pm-txt-color hk-grotest-bold" data-test-id="current-application-status">
            <span> {{ dashboardLoan.statusText.split(' ')[0] }} </span><br />
            <span> {{ dashboardLoan.statusText.split(' ')[1] }} </span>
          </h3>
          <Button
            data-test-id="view-my-loan"
            class="mt-3"
            label="View my loan"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="goToLoan"
          />
        </div>
      </div>
    </template>
    <template v-else #content>
      <div class="todo-completed p-d-flex flex justify-content-center align-items-center" style="height: 217px">
        <div>
          <img :src="require('@/assets/icons/todo_completed.svg')" alt="" />
          <p class="lt-txt-color font-size-1 mt-5">No application under progress</p>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import 'vue3-circle-progress/dist/circle-progress.css';
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
import CircleProgress from 'vue3-circle-progress';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'LoanStatus',
  mixins: [Screensize],
  components: {
    Card,
    CircleProgress,
  },
  data() {
    return {
      isApplicationActive: true,
    };
  },
  computed: {
    ...mapGetters({
      dashboardLoan: 'loans/dashboardLoan',
    }),
  },
  methods: {
    ...mapActions({
      setLoanId: 'loans/setLoanId',
      setLoanStatus: 'loans/setLoanStatus',
      setNewLoanId: 'loans/setNewLoanId',
    }),
    async goToLoan() {
      await this.setLoanId(this.dashboardLoan.id);
      await this.setNewLoanId(this.dashboardLoan.loanReferenceNumber);
      await this.setLoanStatus(this.dashboardLoan.applicationStatus);
      // this.$router.push(`/loan/${this.dashboardLoan.status.loan_id}/application`);
      if (
        this.dashboardLoan.applicationStatus === 'cancelled' ||
        this.dashboardLoan.loanStatus === 'dropped_off' ||
        this.dashboardLoan.loanStatus === 'withdrawn' ||
        this.dashboardLoan.loanStatus === 'rejected' ||
        this.dashboardLoan.loanStatus === 'on_hold'
      ) {
        this.$router.push(`/loan/${this.dashboardLoan.loanReferenceNumber}/summary`);
      } else {
        this.$router.push(`/loan/${this.dashboardLoan.loanReferenceNumber}/application`);
        // await this.fetchLoanSummary();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.dashboard-container {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 992px) {
    flex-wrap: wrap !important;
    justify-content: center;
    .dashboard-current-application {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.text {
  font-size: 3.75rem;
}

a {
  text-decoration: none;
}

:deep(.p-card-body) {
  padding: 2rem !important;
}

.heading {
  font-size: 3rem;
}

.flex-gap-1 {
  gap: 4rem !important;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  border-bottom: 1px solid #02003826;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  @media (max-width: 992px) {
    margin: 0 10px;
  }
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 0.875rem !important;
}

:deep(.highcharts-credits) {
  display: none;
}
:deep(.apexcharts-datalabels) {
  display: none;
}
.completed-percentage {
  top: 35px;
  left: 28px;
  font-size: 2rem !important;
}
.completed-100 {
  left: 20px;
}
.completed-value {
  font-size: 1rem;
}
</style>
