<template>
  <div class="loan-details-container">
    <p class="application-num font-size-1 pm-txt-color">Submit new loan application</p>
    <div class="application-content mt-7 flex justify-content-center">
      <img :src="require('@/assets/Reports.svg')" alt="" />
    </div>
    <div class="footer mt-6">
      <Button
        @click="applyNow"
        label="Submit application"
        :type="'primary'"
        iconPos="right"
        icon="pi pi-chevron-right"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  methods: {
    async applyNow() {
      /*
      localStorage.removeItem("vuex");
      window.localStorage.clear();
      this.$router.push({
        name: "LoanPurposeStep",
        query: { email: this.profileDetails.email, mobile: this.profileDetails.mobile },
      });
      */
      const query = new URLSearchParams();
      query.append('email', this.profileDetails.email);
      query.append('mobile', this.profileDetails.mobile);
      window.location.href = `${process.env.VUE_APP_LOAN_APPLICATION}?${query.toString()}`;
    },
  },
  created() {},
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

a {
  text-decoration: none;
}

.loan-details-container {
  margin-top: 2rem;
  max-width: 400px;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  border: 1px solid rgba(2, 0, 56, 0.15);
  box-sizing: border-box;
  border-radius: 5px;
  flex-basis: true;
  flex-grow: 1;
  .footer {
    text-align: center;
  }
  .application-num {
    // font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    word-break: break-all;
    max-width: 300px;
    text-align: center;
  }
  .address-line {
    font-size: 0.875rem;
  }

  .application-content {
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .application-detail {
      .heading {
        color: #818181;
        margin: 0;
        font-size: 0.875rem;
      }
      .text {
        margin: 0;
        // font-weight: bold;
        font-size: 0.875rem;
      }
      .status {
        color: #29e085;
      }
      .inactive-status {
        color: #2cdd80 !important;
      }
    }
  }
  @media screen and (max-width: 810px) {
    width: unset;
  }
}
.color-red {
  color: #ffbcbd;
}
</style>
