<template>
  <div class="completed-task-container">
    <h3 class="title gr-medium">Completed</h3>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else>
      <div v-if="!completedTaskList.length" class="completed mt-6">
        <div class="icon-wrapper mt-5">
          <img :src="require(`@/assets/icons/uncomplete_task_icon.svg`)" alt="" />
        </div>
        <p class="text lt-txt-color">You have not completed any tasks</p>
      </div>
      <div v-else class="list-wrapper mt-3 mr-4">
        <div v-for="task of completedTaskList" :key="task.id" class="list p-d-flex p-ai-center">
          <div
            @click="
              width < 552 &&
                (task.status === 'review' || task.status === 'in_progress') &&
                task.isButton &&
                amendTask(task)
            "
            :style="
              width < 552 && {
                border: `1.5px solid ${
                  task.status === 'Open'
                    ? '#ffbcbd'
                    : task.status == 'review' || task.status == 'in_progress'
                    ? '#a4bfe0'
                    : '#2cdd80'
                } !important`,
                color: `${
                  task.status === 'Open'
                    ? '#ffbcbd'
                    : task.status == 'review' || task.status == 'in_progress'
                    ? '#a4bfe0'
                    : '#2cdd80'
                }`,
                background: '#ffffff',
              }
            "
            class="icon-content-wrapper col-8"
          >
            <img
              class="mb-none"
              v-if="task.status === 'review' || task.status == 'in_progress'"
              :src="require(`@/assets/icons/clock.svg`)"
              alt=""
            />
            <img
              class="mb-none"
              v-if="task.status === 'completed'"
              :src="require(`@/assets/icons/tick_icon.svg`)"
              alt=""
            />
            <div class="content">
              <p class="heading">
                {{ fromSnakeCase(task.taskTitle) }}
              </p>
              <p class="review" v-if="task.status === 'review' || task.status === 'in_progress'">
                Currently under review
              </p>
              <p class="review" v-if="task.status === 'Completed' || task.status === 'completed'">Complete</p>
            </div>
          </div>
          <Button
            v-if="
              (task.status === 'review' || task.status === 'in_progress') &&
              task.taskTitle != 'accept_unconditional_offer'
            "
            @click="amendTask(task)"
            class="ml-2 mb-none"
            :label="'Amend'"
            type="primary"
            width="120px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fromSnakeCase } from '@/services/format';
import { mapGetters } from 'vuex';

export default {
  props: ['completed', 'progressStatus', 'loanStatus'],
  data() {
    return {
      completedTaskList: [],
      isLoading: false,
      width: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanId: 'loans/selectedLoanId',
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanStatus: 'loans/selectedLoanStatus',
      selectedLoanProgress: 'loans/selectedLoanProgress',
      tasksList: 'loans/tasksList',
    }),
  },
  async created() {
    this.isLoading = true;
    this.setTasksList();
    this.isLoading = false;
    this.width = window.innerWidth;
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    tasksList() {
      this.setTasksList();
    },
  },
  methods: {
    fromSnakeCase,
    onResize() {
      this.width = window.innerWidth;
    },
    /**
     * Filters the user's task task to find the completed and in-progress tasks
     */
    setTasksList() {
      this.completedTaskList =
        this.tasksList?.filter(
          (task) => task.status == 'completed' || task.status == 'review' || task.status == 'in_progress',
        ) || [];
    },
    /**
     * Adds or removes files from an in-progress task that the user has submitted
     */
    amendTask(selectedTask) {
      this.$emit('amend', selectedTask);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.completed-task-container {
  width: 50%;
  border-left: 1px solid #cecece;
  padding-left: 1rem;
  height: 430px;
  overflow: auto;
  @media (max-width: 992px) {
    width: 100%;
    border: none;
    padding-left: 0;
  }

  .title {
    margin-top: 2rem;
  }

  .completed {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  .icon-wrapper {
    .icon {
      font-size: 4.5em;
      color: $primary-text-color;
    }
  }

  .text {
    font-size: 1rem;
    color: #808080;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  .list-wrapper {
    margin-right: 1.5rem;
    margin-top: 1rem;
    @media (max-width: 992px) {
      margin-right: 0;
    }
  }

  .list {
    border-bottom: 1px solid #bbbbbb;
    gap: 1.5rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    @media (max-width: 992px) {
      gap: 0;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .icon-content-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon {
        font-size: 1.7rem;
        color: $primary-color;
      }
    }

    .content {
      .heading {
        margin: 0;
        color: $primary-text-color;
        font-weight: bold;
        font-size: 1rem;
      }

      .text {
        margin: 0;
        color: #808080;
        font-size: 0.9rem;
      }
    }
  }
}
.review {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(2, 0, 56, 0.3);
}

@media (max-width: 545px) {
  .mb-none {
    display: none;
  }

  .icon-content-wrapper {
    width: 100% !important;
    display: block !important;
    text-align: center;
    padding: 0;
    flex: 100%;
    border: 1px solid black;
    border-radius: 4px;
    padding: 1rem;
  }

  .text,
  .review {
    margin-bottom: 0;
  }

  .list {
    justify-content: center !important;
  }
}
</style>
