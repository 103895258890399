<template>
  <div>
    <div class="mt-4 statement-container flex justify-content-between">
      <!-- <h1 class="title hk-grotest-bold pm-txt-color font-size-2_25 sec-title">
        My statements
      </h1> -->
      <div class="email__to">
        Please email
        <a href="mailto: hello@bridgit.com.au" target="_blank">hello@bridgit.com.au</a>
        to request a statement be emailed to you.
      </div>
      <!-- <div class="flex align-items-center">
        <div
          class="lt-txt-color text cursor-pointer"
          v-if="list.length"
          @click="emailSelected"
        >
          Email selected
        </div>
        <Button
          class="ml-4"
          v-if="list.length"
          label="Download selected"
          type="primary"
          @click="downLoadSelected"
        />
      </div> -->
    </div>
    <!-- <StatementsList class="mt-2" @list="getList" /> -->
  </div>
</template>

<script>
import { TECHLEND_BE_URL } from '@/constants.api';
import { _post } from '@/services/crudService';
//import StatementsList from "./statements.vue";
export default {
  name: 'index',
  components: {
    //StatementsList,
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getList(value) {
      this.list = value;
    },
    async downLoadSelected() {
      this.list.data.map((obj) => {
        var a = document.createElement('a'); //Create <a>
        a.href = 'data:file/pdf;base64,' + obj.file; //Image Base64 Goes here
        a.download = obj.id + '.pdf'; //File name Here
        a.click(); //Downloaded file
      });
      /* let statementId = this.list.map((obj) => {
        return {
          id: obj.Id,
        };
      }); */
      /* const result = await _post(TECHLEND_BE_URL + "download-monthly-statement", {
        statementId,
      });
      if (result && result.data) {
        result.data.map((obj) => {
          var a = document.createElement("a"); //Create <a>
          a.href = "data:file/pdf;base64," + obj.file; //Image Base64 Goes here
          a.download = obj.id + ".pdf"; //File name Here
          a.click(); //Downloaded file
        });
      } */
    },
    async emailSelected() {
      try {
        let statementId = this.list.map((obj) => {
          return {
            id: obj.Id,
          };
        });
        await _post(TECHLEND_BE_URL + 'send-mail-monthly-statement', {
          statementId,
        });
        await this.$toast.add({
          severity: 'success',
          detail: 'Email sent successfully',
          life: 3000,
        });
      } catch (err) {
        console.log(err);
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-size: 2.25rem;
}

.text {
  font-size: 0.875rem;
}
.statement-container {
  display: flex !important;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}
.email__to {
  width: 100%;
  padding: 2rem;
  text-align: center;
  background-color: #fff;
}
</style>
