<template>
  <Card class="notification-wrapper">
    <template #title> Notification </template>
    <template #content>
      <Checkbox
        :value="formData.isStatementNotifications"
        @getInputValue="getFormData($event, 'isStatementNotifications')"
        label="Statements notifications"
        id="Statements notifications"
      />
      <Checkbox
        :value="formData.isMarketingOffers"
        @getInputValue="getFormData($event, 'isMarketingOffers')"
        label="I would like to receive direct marketing offers"
        id="I would like to receive direct marketing offers"
      />
      <span @click="save"><Button class="mt-2" label="Save preferences" type="primary" /></span>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
export default {
  name: 'Notification',
  components: {
    Card,
  },
  data() {
    return {
      formData: {
        isStatementNotifications: false,
        isMarketingOffers: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updateNotification: 'settings/updateNotification',
      updateProfile: 'settings/updateProfile',
      getCustomerById: 'settings/getCustomerById',
    }),
    setFormData() {
      for (const [key, value] of Object.entries(this.profileDetails)) {
        this.formData[key] = value;
      }
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      try {
        await this.updateProfile(this.formData);
        await this.getCustomerById();
        await this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Notifications updated Successfully',
          life: 3000,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
  created() {
    this.setFormData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.notification-wrapper {
  padding: 1.875rem;
}

:deep(.p-card-body) {
  padding: 0 !important;
}

:deep(.label-text) {
  color: #020038;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $secondary-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}
</style>
