<template>
  <div class="footer-container content">
    <div class="p-d-flex p-ai-center">
      <img class="mr-2" src="@/assets/bridgit.svg" />
      <div class="ml-8 notes-section">
        <p>
          Eligibility and approval is subject to standard credit assessment and not all amounts, term lengths or rates
          will be available to all applicants. Fees, terms and conditions apply.
        </p>
        <p class="mt-1">We use bank level encryption and security across our platform.</p>
      </div>
    </div>

    <div class="copyright-section">
      <div class="terms-conditions p-d-flex">
        <h1 class="mr-3 cursor-pointer" @click="goToTerms">Terms & Conditions</h1>
        <h1 class="cursor-pointer" @click="goToPrivacy">Privacy Policy</h1>
      </div>
      <span>Copyright &#169; {{ getYear }} Bridgit. All rights reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getYear: '',
    };
  },
  methods: {
    goToTerms() {
      window.open('https://bridgit.com.au/terms-conditions/', '_blank');
    },
    goToPrivacy() {
      window.open('https://bridgit.com.au/privacy-policy/', '_blank');
    },
  },
  created() {
    this.getYear = new Date().getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  background-image: linear-gradient(#f1f6fb, white, #f1f6fb);
  padding: 0 8rem;
  @media (max-width: 992px) {
    display: none;
  }

  .notes-section {
    text-align: left;
    width: 380px;
    font-size: 10px;
    color: #808080;
  }
  .copyright-section {
    justify-content: space-between;
    .terms-conditions {
      h1 {
        font-weight: bold;
        font-size: 12px;
        color: #020038;
        cursor: pointer;
      }
    }
    span {
      font-size: 10px;
      color: #808080;
    }
  }
}
</style>
