<template>
  <div class="mr-b">
    <Panel class="box" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div class="title-wrapper flex align-items-center">
          <img :src="require('@/assets/icons/home_icon_color.svg')" alt="" />
          <p class="title">{{ title }}</p>
        </div>
      </template>

      <div v-for="(item, i) in items" :key="i" class="sec-content">
        <div :class="isMR ? 'sec-row p-ai-center p-jc-between' : 'sec-row p-d-flex p-ai-center p-jc-between'">
          <p class="address-text gr-regular">
            {{ item.line1 }}{{ item.line2 ? ' ' + item.line2 : '' }}<span v-if="item.line1">, </span>
            <span class="capitalize">{{ item.city.toLowerCase() }}</span
            ><span v-if="item.city">, </span> <span v-if="item.state"> {{ item.state?.toUpperCase() }}</span
            ><span v-if="item.state">,</span> Australia<span>{{ item.postCode ? ', ' + item.postCode : '' }}</span>
          </p>
          <div :class="isMR ? 'col p-ai-center' : 'col p-d-flex p-ai-center'">
            <p
              :class="
                isMR
                  ? 'text pm-txt-color p-d-flex justify-content-between p-ai-center'
                  : 'text pm-txt-color p-d-flex p-ai-center'
              "
            >
              <span class="lt-txt-color gr-medium">Settlement date:</span>
              <!-- start settlementDate coming directly from the application -->
              <span v-if="settlementDate">{{ formatDate(settlementDate) }}</span>
              <!-- end settlementDate coming directly from the application -->
              <span v-else-if="item.settlementDate">{{ formatDate(item.settlementDate) }}</span>
              <span v-else>-</span>
            </p>
            <p
              :class="
                isMR
                  ? 'text pm-txt-color p-d-flex justify-content-between p-ai-center'
                  : 'text pm-txt-color p-d-flex p-ai-center'
              "
            >
              <span v-if="isPurchase" class="lt-txt-color gr-medium">Purchase price:</span>
              <span class="lt-txt-color" v-else-if="item.soldStatus !== 'sold'">Estimated valuation:</span>
              <span v-else class="lt-txt-color">Sales price:</span>
              <span v-if="isPurchase"
                >${{ Math.round(Number(item.purchasePrice || item.salePrice || 0)).toLocaleString() }}</span
              >
              <span v-else-if="item.soldStatus == 'sold'">
                ${{ Math.round(Number(item.salePrice || 0)).toLocaleString() }}
              </span>
              <span v-else
                >${{
                  Math.round(
                    Number(item.purchasePrice || item.estimatedValuation || item.salePrice || 0),
                  ).toLocaleString()
                }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { formatDate } from '@/services/format';
import Panel from 'primevue/panel';
import Screensize from '@/mixins/screensize.mixin';

export default {
  components: { Panel },
  mixins: [Screensize],
  props: ['title', 'items', 'isPurchase', 'settlementDate'],
  setup() {
    return {
      formatDate,
    };
  },
  data() {
    return {
      collapsed: true,
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

:deep(.p-panel .p-panel-content) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.p-panel-header) {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $light-text-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

:deep(.p-panel .p-panel-content) {
  border: none !important;
}

:deep(.p-panel .p-panel-header .p-panel-header-icon) {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 1.25rem;
  font-family: Gordita-medium !important;
  margin-left: 0.625rem;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}

.sec-content {
  margin-top: 1rem;

  .title-icon {
    gap: 1rem;

    .title {
      font-size: 1.25rem;
      margin: 0;
    }
  }
  .sec-row {
    gap: 1rem;
    justify-content: space-between;

    .address-text {
      margin: 0;
      width: 50%;
      font-family: Gordita;
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .col {
      gap: 2rem;
      width: 50%;
      @media (max-width: 992px) {
        width: 100%;
        justify-content: space-between;
      }

      p {
        margin: 0;
        gap: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
</style>
