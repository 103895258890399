<template>
  <form class="payment-form">
    <h1 class="title pm-txt-color">Payment</h1>
    <fieldset>
      <div class="field w-full">
        <label for="name">Name</label>
        <InputText autofocus id="name" v-model="formData.name" label="Name" placeholder="Name" />
      </div>
      <div class="field w-full">
        <label for="email">Email</label>
        <InputText id="email" v-model="formData.email" label="Email" placeholder="Email address" />
      </div>
      <div class="field payment-group">
        <label for="street">Address</label>
        <InputText
          id="street"
          data-test-id="card-street-element"
          v-model="formData.street"
          label="Address"
          placeholder="Street"
        />
        <InputText id="city" data-test-id="card-city-element" v-model="formData.city" placeholder="Suburb/City" />
        <InputText
          id="state"
          data-test-id="card-state-element"
          v-model="formData.state"
          placeholder="State"
          class="w-50"
        />
        <InputText
          id="postcode"
          data-test-id="card-postcode-element"
          v-model="formData.postCode"
          placeholder="Postcode"
          class="w-50"
        />
      </div>
      <div class="payment-group mt-4">
        <label>Credit Card</label>
        <div class="w-full">
          <div id="card-number-element" data-test-id="card-number-element" class="card-input"></div>
          <div v-if="cardNumberError" class="card-error">{{ cardNumberError }}</div>
        </div>
        <div class="w-50">
          <div id="card-expiry-element" data-test-id="card-expiry-element" class="card-input"></div>
          <div v-if="cardExpiryError" class="card-error">{{ cardExpiryError }}</div>
        </div>
        <div class="w-50">
          <div id="card-cvc-element" data-test-id="card-cvc-element" class="card-input"></div>
          <div v-if="cardCvcError" class="card-error">{{ cardCvcError }}</div>
        </div>
      </div>
    </fieldset>
    <div class="flex align-items-center justify-content-end gap-4">
      <p class="close mt-6 ml-4 cursor-pointer" @click="closeDialog()">Back</p>
      <Button
        data-test-id="pay-conditional-offer-button"
        class="primary-btn"
        :class="{ dis: loading }"
        style="width: 180px"
        :label="loading ? 'Processing...' : `Pay $${total}`"
        :loading="loading"
        :disabled="hasCardError"
        @click="handleSubmit()"
      />
    </div>
  </form>
</template>

<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { STRIPE_PUBLISHABLE_KEY } from '@/constants';
import { _post } from '@/services/crudService';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import InputText from 'primevue/inputtext';

const CARD_STYLE = {
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: '400',
      fontFamily: 'Arial, sans-serif',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#ccc',
        fontWeight: '400',
      },
    },
    invalid: {
      iconColor: '#c61a54',
      color: '#c61a54',
    },
  },
};

export default {
  name: 'ConditionalOfferPaymentForm',
  components: { InputText },
  emits: ['completed', 'onError', 'close'],
  props: ['total'],
  setup(props, context) {
    const toast = useToast();

    const store = useStore();
    const taskId = computed(() => store.getters['loans/selectedTaskId']);
    const profileDetails = computed(() => store.getters['settings/profileDetails']);

    const formData = reactive({
      name: '',
      email: '',
      street: '',
      city: '',
      state: '',
      postCode: '',
    });

    watch(
      profileDetails,
      (profile) => {
        Object.assign(formData, {
          name: [profile.firstName, profile.lastName].filter(Boolean).join(' '),
          email: profile.email,
          street: [profile.line1, profile.line2].filter(Boolean).join(', '),
          city: profile.city,
          state: profile.state?.toUpperCase(),
          postCode: profile.postCode,
        });
      },
      {
        immediate: true,
      },
    );

    let stripe = null;
    let loading = ref(true);
    let elements = null;

    const cardNumberElement = ref(null);
    const cardExpiryElement = ref(null);
    const cardCvcElement = ref(null);
    const cardNumberChangeEvent = ref({ empty: true });
    const cardExpiryChangeEvent = ref({ empty: true });
    const cardCvcChangeEvent = ref({ empty: true });

    const submitted = ref(false);
    const cardNumberError = computed(() =>
      cardNumberChangeEvent.value.error
        ? cardNumberChangeEvent.value.error.message
        : cardNumberChangeEvent.value.empty && submitted.value
        ? 'Your card number is required'
        : '',
    );

    const cardExpiryError = computed(() =>
      cardExpiryChangeEvent.value.error
        ? cardExpiryChangeEvent.value.error.message
        : cardExpiryChangeEvent.value.empty && submitted.value
        ? "Your card's expiry date is required"
        : '',
    );

    const cardCvcError = computed(() =>
      cardCvcChangeEvent.value.error
        ? cardCvcChangeEvent.value.error.message
        : cardCvcChangeEvent.value.empty && submitted.value
        ? "Your card's security code is required"
        : '',
    );

    const hasCardError = computed(() => !!(cardNumberError.value || cardExpiryError.value || cardCvcError.value));

    onMounted(async () => {
      stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      elements = stripe.elements();

      cardNumberElement.value = elements.create('cardNumber', {
        ...CARD_STYLE,
        showIcon: true,
        placeholder: 'Card Number',
      });
      cardNumberElement.value.mount('#card-number-element');

      cardExpiryElement.value = elements.create('cardExpiry', CARD_STYLE);
      cardExpiryElement.value.mount('#card-expiry-element');

      cardCvcElement.value = elements.create('cardCvc', CARD_STYLE);
      cardCvcElement.value.mount('#card-cvc-element');

      cardNumberElement.value.on('change', (event) => {
        cardNumberChangeEvent.value = event;
      });
      cardExpiryElement.value.on('change', (event) => {
        cardExpiryChangeEvent.value = event;
      });
      cardCvcElement.value.on('change', (event) => {
        cardCvcChangeEvent.value = event;
      });

      loading.value = false;
    });

    async function handleSubmit() {
      submitted.value = true;

      if (hasCardError.value) return;

      loading.value = true;
      try {
        const billingDetails = {
          name: formData.name,
          email: formData.email,
          address: {
            line1: formData.street,
            city: formData.city,
            state: formData.state,
          },
        };

        const { data } = await _post(TECHLEND_BE_URL + API.TASKS.ACCEPT_CONDITIONAL_OFFER + `?taskId=${taskId.value}`);
        const { client_secret } = data.paymentIntent;
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement.value,
          billing_details: billingDetails,
        });
        const confirmPayment = await stripe.confirmCardPayment(client_secret, {
          payment_method: paymentMethod.id,
        });

        if (confirmPayment.paymentIntent?.status == 'succeeded') {
          context.emit('completed');
        } else {
          const error = confirmPayment.error;
          toast.add({
            severity: 'error',
            summary: 'Error: Your payment was not processed',
            detail: error?.message || 'A server error has occurred',
            life: 0,
          });
        }
      } catch (error) {
        console.error(error);
        context.emit('onError', { title: 'Error', details: 'A server error has occurred' });
      } finally {
        loading.value = false;
      }
    }

    function closeDialog() {
      context.emit('close');
    }

    return {
      profileDetails,
      loading,
      formData,
      cardNumberError,
      cardExpiryError,
      cardCvcError,
      hasCardError,
      handleSubmit,
      closeDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  background-color: #fff;
  padding: 1rem;
  border-radius: 3px;
  width: 600px;
}

.card-input {
  padding: 10px;
  height: 40px;
  width: 100%;
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px;

  &.StripeElement--focus {
    box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3);
  }
}

.card-error {
  width: 100%;
  color: var(--pink-600);
  font-size: 12px;
}

.payment-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  :deep(.p-inputtext) {
    height: 40px;
    width: 100%;
    border: 1px solid rgba(2, 0, 56, 0.15);
    border-radius: 4px !important;
    padding: 10px;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3);
      border-color: rgba(2, 0, 56, 0.15);
    }
  }

  :deep(label) {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }

  .w-50 {
    width: calc(50% - 0.25rem) !important;
  }

  :deep(.p-inputtext::placeholder) {
    color: #ccc;
  }
}

.payment-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}

button {
  margin-top: 30px;
  background: #2cdd80;
  color: white;
  border: unset;
  width: 100px;
  height: 40px;
  border-radius: 4px;
}

.dis {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
</style>
