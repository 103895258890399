<template>
  <div class="toolbar-container">
    <div class="menu-icon-container">
      <img class="menu-icon" v-if="!toggleSideNavClicked" @click="toggleSidenav" src="@/assets/icons/menu_icon.svg" />
      <img class="menu-icon" v-if="toggleSideNavClicked" @click="closeSidenav" src="@/assets/icons/close_icon.svg" />
    </div>
    <div>
      <img @click="goToDashboard" class="logo-icon cursor-pointer" src="@/assets/bridgit.svg" />
    </div>

    <div>
      <div class="right-col p-d-flex p-ai-center">
        <!-- <div class="bell-icon icon-wrapper">
          <img
            class="icon"
            :src="require(`@/assets/icons/bell_icon.svg`)"
            alt=""
          />
        </div> -->
        <div class="p-d-flex p-ai-center user-avatar-name-wrapper" v-click-outside="onClickOutside">
          <!-- <img class="cursor-pointer" src="@/assets/icons/profile-circle.svg" /> -->
          <div class="user-name capital">
            <h3 class="name pm-txt-color cursor-pointer" @click="toggleDropdownMenu">
              {{ profileDetails.firstName ? profileDetails.firstName : 'Customer' }}
              {{ profileDetails.lastName }}
            </h3>
          </div>
          <div class="drop-down-menu">
            <div @click="toggleDropdownMenu" class="icon-wrapper">
              <img class="cursor-pointer" src="@/assets/icons/angle-down.svg" />
            </div>
            <div @click="showDropdownMenu = !showDropdownMenu" v-if="showDropdownMenu" class="menu-items">
              <div
                @click="navigateTo('https://www.bridgit.com.au/terms-conditions')"
                class="item-list p-d-flex p-ai-center p-jc-sm-between"
              >
                <p class="text">Terms & Conditions</p>
                <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                  <i class="icon-right pi pi-angle-right"></i>
                </div>
              </div>
              <div
                @click="navigateTo('https://www.bridgit.com.au/privacy-policy')"
                class="item-list p-d-flex p-ai-center p-jc-sm-between"
              >
                <p class="text">Privacy Policy</p>
                <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                  <i class="icon-right pi pi-angle-right"></i>
                </div>
              </div>
              <div @click="goToContact" class="item-list p-d-flex p-ai-center p-jc-sm-between">
                <p class="text">Contact Us</p>
                <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                  <i class="icon-right pi pi-angle-right"></i>
                </div>
              </div>
              <div @click="goToSettings" class="item-list p-d-flex p-ai-center p-jc-sm-between">
                <p class="text">Settings</p>
                <div class="list-icon-wrapper p-d-flex p-ai-center p-jc-center">
                  <i class="icon-right pi pi-angle-right"></i>
                </div>
              </div>
              <div @click="signout" class="item-list p-d-flex p-ai-center p-jc-sm-between">
                <p class="text">Sign Out</p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Toolbar from "primevue/toolbar";

export default {
  props: ['toggleSideNavClicked', 'toolBarKey'],
  name: 'ToolbarComponent',
  components: {
    // Toolbar,
  },
  data() {
    return {
      showDropdownMenu: false,
      showSideNav: '',
    };
  },
  methods: {
    ...mapActions({
      setLogin: 'auth/setLogin',
      getCustomerById: 'settings/getCustomerById',
      setDashboard: 'loans/setDashboard',
    }),
    onClickOutside() {
      this.showDropdownMenu = false;
    },
    toggleDropdownMenu() {
      return (this.showDropdownMenu = !this.showDropdownMenu);
    },
    navigateTo(path) {
      window.open(path, '_blank');
    },
    signout() {
      this.setLogin({ token: false });
      this.$router.push('/login');
    },
    goToSettings() {
      this.$router.push('/settings');
    },
    goToContact() {
      this.$router.push('/contact');
    },
    goToDashboard() {
      this.$router.push('/dashboard');
      this.setDashboard(true);
    },
    toggleSidenav() {
      this.showSideNav = true;
      this.$emit('change', true);
    },
    closeSidenav() {
      this.showSideNav = false;
      this.$emit('change', false);
    },
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  async created() {
    await this.getCustomerById();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}
.toolbar-container {
  display: flex;
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    .logo-icon {
      width: 130px;
    }
  }
}
.menu-icon-container {
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  .menu-icon {
    width: 40px;
    height: 40px;
  }
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;

  gap: 2rem;

  .bell-icon {
    position: relative;
    border-right: 1px solid $light-text-color;
    padding-right: 1.875rem;
    @media (max-width: 992px) {
      border-right: none;
      padding-right: 0;
    }

    .icon {
      font-size: 1.5rem;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: orange;
        border-radius: 50%;
        right: 0;
        top: 2px;
      }
    }
  }

  .user-avatar-name-wrapper {
    gap: 1.875rem;
    position: relative;
    @media (max-width: 992px) {
      gap: 0;
    }

    .user-avatar {
      width: 2.45rem;
      height: 2.45rem;
      border-radius: 50%;
      border: 1px solid $light-text-color;
    }

    .user-name {
      .name {
        margin: 0;
        font-size: 1.4rem;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }

    .drop-down-menu {
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $light-text-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        color: $light-text-color;
        font-size: 0.8rem;
      }

      .menu-items {
        position: absolute;
        top: 4rem;
        right: 0;
        background-color: $white-color;
        width: 230px;
        padding: 1.25rem 1.875rem;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        z-index: 99;
        border: none;
        user-select: none;

        &:after {
          content: '';
          position: absolute;
          display: block;
          top: -11px;
          right: 4%;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
          border-bottom: 12px solid $white-color;
          font-size: 0;
          line-height: 0;
          width: 0;
        }

        .item-list {
          cursor: pointer;

          .text {
            font-size: 1rem;
            margin: 0;
            margin: 0.5rem 0;
            color: $primary-text-color;
          }
        }
      }
    }
  }
}
</style>
