<template>
  <div>
    <div class="mt-4" v-if="isDashboard">
      <!-- <h1 class="title pm-txt-color font-size-2_25 sec-title">Dashboard</h1> -->
      <Spinner v-if="isLoading" class="flex" />
      <div v-else :class="isMR ? '' : 'p-grid'">
        <div :class="isMR ? '' : 'col-9'">
          <LoanStatus />
          <Task />
          <Contact :class="isMR ? 'mt-3' : 'mt-4'" />
        </div>
        <div :class="isMR ? 'mt-3' : 'col-3'">
          <Blog />
          <Refer @refer="goToClient" />
        </div>
      </div>
      <!-- <Blog /> -->
    </div>
    <!-- <div v-else>
      <h1 :class="isMR ? 'module-title mt-3' : 'module-title mt-6'">
        Refer a friend
      </h1>
      <div
        :class="
          isMR
            ? 'flex-wrap justify-content-center mt-3 pb-5'
            : 'flex justify-content-between mt-3 pb-5'
        "
      >
        <div :class="isMR ? '' : 'col col-8'" :visible="false">
          <ReferAndEarn />
          <div :class="isMR ? 'flex-wrap mt-3' : 'flex mt-3'">
            <SendReferral :class="isMR ? '' : 'col col-7'" />
            <ReferanceLink :class="isMR ? 'mt-3' : 'col ml-2'" />
          </div>
        </div>
        <div :class="isMR ? 'mt-4' : 'col col-4 ml-3'">
          <Earnings />
          <Payment class="mt-4" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Blog from './Blog.vue';
import Contact from './Contact.vue';
import LoanStatus from './LoanStatus.vue';
import Task from './ToDoTask.vue';
// import Refer from "./Refer.vue";
import Screensize from '@/mixins/screensize.mixin';
// import ReferAndEarn from "./ReferAndEarn.vue";
// import SendReferral from "./SendReferral.vue";
// import ReferanceLink from "./ReferanceLink.vue";
// import Earnings from "./Earnings.vue";
// import Payment from "./Payment.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'index',
  mixins: [Screensize],
  components: {
    LoanStatus,
    Blog,
    Task,
    Contact,
    // Refer,
    // ReferAndEarn,
    // SendReferral,
    // ReferanceLink,
    // Earnings,
    // Payment,
  },
  data() {
    return {
      test: true,
      isDashboard: true,
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getDashboardLoan: 'loans/getDashboardLoan',
      getLoanStatus: 'loans/getLoanStatus',
    }),
    goToClient() {
      this.isDashboard = false;
    },
  },
  async created() {
    this.isLoading = true;
    await this.getDashboardLoan();
    if (this.$route.query && this.$route.query.type) {
      this.isDashboard = this.$route.query.type === 'broker' ? true : false;
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      isDashboardPage: 'loans/isDashboardPage',
    }),
  },
  watch: {
    isDashboardPage() {
      this.isDashboard = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-family: Gordita-bold;
  // color: $secondary-color;
}
</style>
