<template>
  <div>
    <Spinner class="flex align-items-center" v-if="isLoading" />
    <Card v-else>
      <template #content>
        <div class="justify-content-between account-overview">
          <div :class="isMR ? 'col' : 'col col-5'" class="flex justify-content-between">
            <div class="title">Client name</div>
            <div class="name">
              {{ formData.firstName || '-' }} {{ formData.middleName || '' }}
              {{ formData.lastName || '' }}
            </div>
          </div>
          <div :class="isMR ? 'col' : 'col col-6'" class="flex justify-content-between">
            <div class="title">Client number</div>
            <div class="name">{{ formData.mobilePhoneNumber }}</div>
          </div>
        </div>
        <div class="title mt-3 ml-2">Personal information</div>
        <!-- First row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-6" :class="isXS ? 'col-12' : ''">
            <div>Mobile</div>
            <TextField
              width="100%"
              placeholder="Enter mobile number"
              @onInput="getFormData($event, 'mobilePhoneNumber')"
              :value="formData.mobilePhoneNumber"
              :disabled="true"
            />
          </div>
          <div class="col col-6" :class="isXS ? 'col-12' : ''">
            <div>Email</div>
            <TextField
              width="100%"
              placeholder="Enter email"
              @onInput="getFormData($event, 'email')"
              :value="formData.email"
              :disabled="true"
            />
          </div>
        </div>
        <!-- Second row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Address line 1</div>
            <TextField
              width="100%"
              placeholder="Enter address line 1"
              @onInput="getFormData($event, 'line1')"
              :value="formData.line1"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Address line 2</div>
            <TextField
              width="100%"
              placeholder="Enter address line 2"
              @onInput="getFormData($event, 'line2')"
              :value="formData.line2"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>City</div>
            <TextField
              width="100%"
              placeholder="Enter city"
              @onInput="getFormData($event, 'city')"
              :value="formData.city"
            />
          </div>
        </div>
        <!-- Third row -->
        <div class="flex" :class="isXS ? 'flex-column' : ''">
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>State</div>
            <Dropdown
              @change="getFormData($event, 'state')"
              :value="formData.state?.toUpperCase()"
              :items="statesList"
              placeholder="State"
              width="100%"
              :key="stateKey"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Postal code</div>
            <InputNumberField
              @onInput="getFormData($event ? Number($event) : $event, 'postCode')"
              :value="Number(formData.postCode)"
              width="100%"
              placeholder="Enter postal code"
              type="number"
              :maxDigit="3"
            />
          </div>
          <div class="col col-4" :class="isXS ? 'col-12' : ''">
            <div>Country</div>
            <Dropdown
              width="100%"
              class="full-width"
              :items="['Australia']"
              @change="getFormData($event, 'country')"
              :value="'Australia'"
              placeholder="Country"
              :key="countryKey"
            />
          </div>
        </div>
        <span @click="save">
          <Button :disabled="isSubmitDisabled()" class="mt-3 ml-2" label="Save changes" type="primary"
        /></span>
      </template>
    </Card>
  </div>
</template>

<script>
import { STATES } from '@/constants';
import { email, maxLength, minLength, minValue, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'AccountComponent',
  mixins: [Screensize],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        line1: { required },
        // line2: { required },
        city: { required },
        state: { required },
        postCode: {
          minValue: minValue(0),
          minLength: minLength(3),
          maxLength: maxLength(4),
          required,
        },
        country: { required },
        email: { email, required },
        mobilePhoneNumber: {
          required,
          // checkMobileNumbers: (mobile) => {
          //   const result = checkMobileNumber(mobile);
          //   return result.isValid;
          // },
        },
      },
    };
  },
  components: {
    Card,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        firstName: '',
        lastName: '',
        middleName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        mobile: '',
        email: '',
      },
      statesList: STATES,
      stateKey: 0,
      countryKey: 10,
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updateProfile: 'settings/updateProfile',
      getCustomerById: 'settings/getCustomerById',
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    setFormData() {
      for (const [key, value] of Object.entries(this.profileDetails)) {
        this.formData[key] = value;
      }
      this.stateKey += 1;
      this.countryKey += 1;
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async save() {
      if (!this.v$.$invalid) {
        try {
          this.formData.postCode = this.formData.postCode.toString();
          this.formData.state = this.formData.state.toLowerCase();
          await this.updateProfile(this.formData);
          await this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Profile updated Successfully',
            life: 3000,
          });
          await this.getCustomerById();
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err.response.data.message,
              life: 3000,
            });
          } else {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong',
              life: 3000,
            });
          }
        }
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.getCustomerById();
    await this.setFormData();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  font-size: 1rem !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
.account-overview {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  display: flex;
  @media (max-width: 992px) {
    flex-direction: column;
  }
}
.title {
  color: rgba(2, 0, 56, 0.3);
  font-family: Gordita-medium !important;
}
.flex-column {
  flex-direction: column;
}
.name {
  color: #020038;
  font-weight: 600;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
}
.full-width {
  width: 100%;
}
</style>
