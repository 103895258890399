<template>
  <div>
    <Spinner class="flex align-items-center" v-if="isLoading" />
    <Card v-else class="notification-wrapper">
      <template #content>
        <div>
          <div class="title-text">
            Please enter the details of the account you would like to receive surplus funds and payment when referring a
            friend
          </div>
          <div class="mt-4 field-title">Account name</div>
          <TextField
            width="100%"
            placeholder="Your name as registered on the account"
            @onInput="getFormData($event, 'bankAccountName')"
            :value="formData.bankAccountName"
          />
          <div class="row flex mt-4">
            <div class="col-sm">
              <div class="field-title">BSB</div>
              <TextField
                width="100%"
                placeholder="Your BSB code"
                @onInput="getFormData($event, 'bankBSBNumber')"
                :value="formData.bankBSBNumber"
                type="number"
              />
            </div>
            <div class="col-sm field-account-title">
              <div>Account number</div>
              <TextField
                width="100%"
                placeholder="Your account number"
                @onInput="getFormData($event, 'bankAccountNumber')"
                :value="formData.bankAccountNumber"
                type="number"
              />
            </div>
          </div>
          <span @click="save">
            <Button :disabled="isSubmitDisabled()" label="Save changes" type="primary" class="mt-4" />
          </span>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { integer, maxLength, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';
import Screensize from '@/mixins/screensize.mixin';

export default {
  name: 'Payment',
  mixins: [Screensize],
  components: {
    Card,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        bankAccountName: { required },
        bankAccountNumber: { required, integer },
        bankBSBNumber: {
          required,
          integer,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      formData: {
        bankAccountName: '',
        bankAccountNumber: '',
        bankBSBNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
  methods: {
    ...mapActions({
      updatePaymentDetails: 'settings/updatePaymentDetails',
      getCustomerById: 'settings/getCustomerById',
      updateProfile: 'settings/updateProfile',
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },

    setFormData() {
      for (const [key, value] of Object.entries(this.profileDetails)) {
        this.formData[key] = value;
      }
      this.stateKey += 1;
      this.countryKey += 1;
    },

    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    async save() {
      if (!this.v$.$invalid) {
        try {
          await this.updateProfile(this.formData);
          await this.getCustomerById();
          await this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Payment details updated Successfully',
            life: 3000,
          });
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err.response.data.message,
              life: 3000,
            });
          } else {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong',
              life: 3000,
            });
          }
        }
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.getCustomerById();
    await this.setFormData();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.notification-wrapper {
  padding: 1.875rem;
}
.title-text {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem !important;
}

:deep(.p-card-body) {
  padding: 0 !important;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $secondary-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}
.col {
  padding: unset;
}
.field-title {
  color: #020038;
  font-weight: 500;
  font-size: 14px;
}
.field-account-title {
  color: #020038;
  font-weight: 500;
  font-size: 14px;
  margin-left: 1.5rem;
  @media (max-width: 575px) {
    margin-top: 1.5rem;
    margin-left: 0px;
  }
}
</style>
