<template>
  <div class="modal-container">
    <Dialog :visible="display" :modal="true" :style="[isMR ? { width: '90vw' } : { width: '70vw' }]">
      <template #header>
        <h1 class="title pm-txt-color">Discharge request</h1>
      </template>
      <Spinner v-if="isLoadingPage" class="flex" />

      <div v-else class="modal-content">
        <img width="40" src="@/assets/icons/discharge.svg" />
        <div class="discharge-details mt-3">Discharge request details</div>
        <p class="desc pm-txt-color mt-2">
          I/We would like to commence the discharge process to repay my/our loan account for the property(s) below:
        </p>
        <!-- <p class="desc pm-txt-color" style="width: 100%">Estimated payout date</p>
        <DatePicker
          @change="getFormData($event, 'estimated_payout_date')"
          width="100%"
          style="width: 100%"
        /> -->

        <div class="section-1 mt-3 p-3">
          <div class="details-content mb-2">The loan will be repaid by way of</div>
          <Checkbox
            @getInputValue="(e) => (showPurchaseProperties = e)"
            label="The property is sold"
            id="The property is sold"
            @click="changePurchaseProperties"
            :value="showPurchaseProperties"
          />
          <Checkbox
            @getInputValue="(e) => (showLoanRefinancing = e)"
            label="The loan is to be refinanced"
            id="The loan is to be refinanced"
            @click="changeLoanRefinancing"
            :value="showLoanRefinancing"
          />
          <Checkbox
            @getInputValue="(e) => (showCashPayment = e)"
            label="The loan will be paid with cash"
            id="The loan will be paid with cash"
            @click="changeCashPayment"
            :value="showCashPayment"
          />
        </div>
        <div v-if="showPurchaseProperties">
          <div v-if="showPurchaseProperties" class="section-1 mt-3 p-3">
            <div class="details-content mb-2">Properties for Sale</div>
            <div class="flex purchase-properties">
              <!-- <div class="col">
                <div class="details-content-subtitle mb-2">Purchase Properties</div>
                <div v-for="(item, i) in buyingDetails" :key="i">
                  <Checkbox
                    :value="item.selected"
                    @click="changePurchaseProperties"
                    @getInputValue="(e) => (item.selected = e)"
                    :label="`${item.line1} ${item.city} ${item.state}
                ${item.country} ${item.postCode}`"
                    :id="`${item.line1}
                ${item.city} ${item.state} ${item.country} ${item.postCode}`"
                  />
                </div>
              </div> -->
              <div class="col">
                <!-- <div class="details-content-subtitle mb-2">
                  Properties for Sale
                </div> -->
                <div v-for="(item, i) in sellingDetails" :key="i">
                  <Checkbox
                    :value="item.selected"
                    @click="changePurchaseProperties"
                    @getInputValue="(e) => (item.selected = e)"
                    :label="`${item.line1} ${item.city} ${item.state}
                ${item.country} ${item.postCode}`"
                    :id="`${item.line1}
                ${item.city} ${item.state} ${item.country} ${item.postCode}`"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-for="(item, i) in sellingDetails" :key="i">
            <div v-if="item.selected" class="section-1 mt-3 p-3">
              <div v-if="item.selected" class="details-content mb-2">
                {{
                  `${item.line1} ${item.city} ${item.state}
                ${item.country} ${item.postCode}`
                }}
              </div>
              <div v-if="item.selected" class="flex purchase-properties">
                <div class="col">
                  <div class="flex purchase-properties">
                    <InputWithIcon
                      class="col"
                      label="Sale price"
                      placeholder="Enter amount"
                      :icon="dollar"
                      @onInput="getSellingDetails($event, 'salePrice', i)"
                      type="number"
                    />
                    <InputWithIcon
                      :class="isMR ? 'mt-2' : 'ml-2'"
                      class="col"
                      label="Valuation price"
                      placeholder="Enter amount"
                      :icon="dollar"
                      type="number"
                      @onInput="getSellingDetails($event, 'valuationPrice', i)"
                    />
                  </div>
                  <div class="flex mt-4">
                    <div :class="isMR ? '' : 'col'">
                      <p class="settlement-date">Settlement Date</p>
                      <DatePicker
                        @change="getSellingDetails($event, 'settlementDate', i)"
                        placeholder="DD/MM/YYYY"
                        :minDate="new Date()"
                        yearRange="2022:2122"
                      />
                    </div>
                    <div class="col ml-2"></div>
                  </div>
                </div>
                <div :class="isMR ? 'mt-4' : 'ml-4'" class="col">
                  <FileUpload
                    @files="getSellingDetails($event, 'documentURL', i)"
                    title="Copy of the contract of sale"
                    :id="`Copy of the contract of sale-selling${i}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showLoanRefinancing" class="section-1 mt-3 p-3">
          <div class="details-content mb-2">Loan Refinancing</div>
          <div class="flex purchase-properties">
            <div :class="isMR ? '' : 'col'">
              <div class="flex">
                <InputWithIcon
                  :class="isMR ? '' : 'col col-6'"
                  label="Amount to be refinanced"
                  placeholder="Enter amount"
                  :icon="dollar"
                  type="number"
                  @onInput="getLoanRefinace($event ? $event : Number($event), 'loanRefinancingAmount')"
                />
              </div>
              <div class="flex mt-4">
                <div :class="isMR ? '' : 'col col-6'">
                  <p class="settlement-date">Date of refinancing</p>
                  <DatePicker
                    @change="getLoanRefinace($event, 'loanRefinancingDate')"
                    :minDate="new Date()"
                    placeholder="DD/MM/YYYY"
                    yearRange="2022:2122"
                  />
                </div>
              </div>
            </div>
            <div :class="isMR ? 'mt-4' : 'col ml-4'">
              <FileUpload
                @files="getLoanRefinace($event, 'loanRefinancingdocumentURL')"
                title="Copy of conditional approval of sale"
                :id="'Copy of conditional approval of sale'"
              />
            </div>
          </div>
        </div>

        <div v-if="showCashPayment" class="section-1 mt-3 p-3">
          <div class="details-content mb-2">Cash Repayment</div>
          <div class="flex purchase-properties">
            <div :class="isMR ? '' : 'col'">
              <div class="flex">
                <InputWithIcon
                  type="number"
                  :class="isMR ? '' : 'col col-6'"
                  label="Amount to be repaid"
                  placeholder="Enter amount"
                  :icon="dollar"
                  @onInput="getCashPayment($event, 'cashRepaymentAmount')"
                />
              </div>
              <div class="flex mt-4">
                <div :class="isMR ? '' : 'col col-6'">
                  <p class="settlement-date">Date of repayment</p>
                  <DatePicker
                    @change="getCashPayment($event, 'cashRepaymentDate')"
                    :minDate="new Date()"
                    placeholder="DD/MM/YYYY"
                    yearRange="2022:2122"
                  />
                </div>
              </div>
            </div>

            <div :class="isMR ? 'mt-4' : 'col ml-4'">
              <FileUpload
                @files="getCashPayment($event, 'cashRepaymentdocumentURL')"
                title="Bank Statement confirming Cash Funds"
                id="Bank Statement confirming Cash Funds"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="details-content-subtitle mt-3 mb-2">Solicitor/conveyancer details</div>
          <div class="flex purchase-properties" :key="formDataKey">
            <TextField
              @onInput="getSolicitorData($event, 'solicitorFirstName')"
              class="col"
              width="100%"
              :value="formData.solicitorFirstName"
              label="First name"
            />
            <TextField
              @onInput="getSolicitorData($event, 'solicitorLastName')"
              class="col"
              :class="isMR ? 'mt-2' : 'ml-2'"
              width="100%"
              label="Last name"
              :value="formData.solicitorLastName"
            />
            <TextField
              @onInput="getSolicitorData($event, 'solicitorEmail')"
              class="col"
              :class="isMR ? 'mt-2' : 'ml-2'"
              width="100%"
              label="Email"
              :value="formData.solicitorEmail"
            />
          </div>
          <div class="flex mt-2 purchase-properties">
            <TextField
              @onInput="getSolicitorData($event, 'solicitorPhone')"
              class="col"
              width="100%"
              label="Phone"
              type="number"
              :value="formData.solicitorPhone"
            />
            <TextField
              @onInput="getSolicitorData($event, 'solicitorCompany')"
              class="col"
              :class="isMR ? 'mt-2' : 'ml-2'"
              width="100%"
              label="Company"
              :value="formData.solicitorCompany"
            />
            <TextField
              @onInput="getSolicitorData($event, 'solicitorAddress')"
              class="col"
              :class="isMR ? 'mt-2' : 'ml-2'"
              width="100%"
              label="Address"
              :value="formData.solicitorAddress"
            />
          </div>
        </div>

        <div class="acknowledgement mt-3">
          I/We acknowledge the following conditions: <br />
          • The loan repayments are due in full until the day discharge is complete;
          <br />
          • That on settlement a release of Mortgage so far as the land is concerned will be given but I/we shall not be
          released from the personal covenants of the Mortgage until audit of the account is completed. Any necessary
          adjustments of a refund will be forwarded to me/us via post.
          <br />
          • I/we similarly understand that if the audit shows further monies are due I/we shall be asked to pay them.
          <br />
          • Bridgit's Solicitor requires five (5) days notice for booking of the discharge settlement.
        </div>

        <div>
          <div class="details-content-subtitle mt-3 mb-2">Applicant 1 signature</div>
          <div class="flex purchase-properties">
            <TextField
              class="col"
              :disabled="true"
              width="100%"
              label="First name"
              v-if="selectedLoanDetails.applicants && selectedLoanDetails.applicants[0]"
              :value="selectedLoanDetails.applicants[0].firstName"
            />
            <TextField
              :disabled="true"
              class="col"
              :class="isMR ? 'mt-2' : 'ml-2'"
              width="100%"
              label="Last name"
              v-if="selectedLoanDetails.applicants && selectedLoanDetails.applicants[0]"
              :value="selectedLoanDetails.applicants[0].lastName"
            />
          </div>
          <div class="mt-4 p-float-label hk-grotest-medium">Draw your signature with your mouse or touch.</div>
          <div class="signature-container mt-2">
            <VueSignaturePad
              :customStyle="{
                background: 'white',
                border: '1px solid #02003826',
                borderRadius: '4px',
              }"
              height="200px"
              ref="signaturePad"
              :options="{ onBegin, onEnd }"
            />
            <div class="clear">
              <span @click="clearSignature">Clear</span>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="modal-foodter">
          <!-- isSubmitDisabled() || -->
          <Button :disabled="isLoading" label="Submit request" type="primary" @click="saveData" />
          <p class="close" @click="closeDialog">Close</p>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { base64toFile } from '@/services/FileConvert';
import { email, helpers, integer, maxLength, minLength, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Dialog from 'primevue/dialog';
import Dollar from '@/assets/icons/dollar.svg';
import FileUpload from '../FileUploadComponent.vue';
// import { checkMobileNumber } from "../../../../../../services/validatePhNo";
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _patch, _post } from '@/services/crudService';
import { uploadFileToS3 } from '@/services/fileUploadService';
import Screensize from '@/mixins/screensize.mixin';

export default {
  mixins: [Screensize],
  props: ['display', 'openDialog', 'closeDialog', 'change', 'supportingDocumentId', 'taskId'],
  components: { Dialog, FileUpload },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formDataKey: 10,
      documentURL: [],
      imgsrc: '',
      dollar: Dollar,
      showPurchaseProperties: false,
      showLoanRefinancing: false,
      showCashPayment: false,
      formData: {
        // estimated_payout_date: "",
        repaidBy: [],
        purchase_properties: [],
        outgoingProperties: [],
        loanRefinancingAmount: '',
        loanRefinancingDate: '',
        loanRefinancingdocumentURL: [],
        cashRepaymentAmount: '',
        cashRepaymentDate: '',
        cashRepaymentdocumentURL: [],
        solicitorFirstName: '',
        solicitorLastName: '',
        solicitorEmail: '',
        solicitorPhone: '',
        solicitorCompany: '',
        solicitorAddress: '',
        applicantFirstName: '',
        applicantLastName: '',
        applicantSignature: '',
        applicantType: '',
        applicantId: '',
      },
      buyingDetails: [],
      sellingDetails: [],
      isLoading: false,
      isLoadingPage: true,
    };
  },
  validations() {
    let sellingDetails = this.sellingDetails.map((data) => {
      if (data.selected === true) {
        return true;
      } else {
        return false;
      }
    });
    if (this.showCashPayment && this.showLoanRefinancing && this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          // estimated_payout_date: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showCashPayment && this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showLoanRefinancing && this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showLoanRefinancing && this.showPurchaseProperties) {
      return {
        formData: {
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else if (this.showLoanRefinancing && this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showCashPayment && this.showPurchaseProperties) {
      return {
        formData: {
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else if (this.showCashPayment && this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showLoanRefinancing && this.showCashPayment) {
      return {
        formData: {
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else if (this.showPurchaseProperties) {
      return {
        formData: {
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else if (this.showPurchaseProperties && sellingDetails[0] === true) {
      return {
        formData: {
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
          outgoingProperties: {
            $each: helpers.forEach({
              salePrice: { required },
              valuationPrice: { required },
              settlementDate: { required },
              documentURL: { required },
            }),
          },
        },
      };
    } else if (this.showLoanRefinancing) {
      return {
        formData: {
          loanRefinancingAmount: { required },
          loanRefinancingDate: { required },
          loanRefinancingdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else if (this.showCashPayment) {
      return {
        formData: {
          cashRepaymentAmount: { required },
          cashRepaymentDate: { required },
          cashRepaymentdocumentURL: { required },
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    } else {
      return {
        formData: {
          solicitorFirstName: { required },
          solicitorLastName: { required },
          solicitorEmail: { required, email },
          solicitorPhone: {
            required,
            integer,
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
          solicitorCompany: { required },
          solicitorAddress: { required },
          applicantSignature: { required },
        },
      };
    }
  },
  methods: {
    ...mapActions({
      getSolictorDetailsById: 'loans/getSolictorDetailsById',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid || !this.isSubmitRequestEnabled;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.imgsrc = '';
    },
    async onEnd() {
      // isEmpty,
      this.formData.applicantSignature = 'success';
      const { data } = this.$refs.signaturePad.saveSignature();
      const file = await base64toFile(data, 'applicant-signature.png');
      this.formData.applicantSignature = await uploadFileToS3(this.supportingDocumentId, file);
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async getLoanRefinace(value, fieldName) {
      if (fieldName === 'loanRefinancingdocumentURL') {
        this.formData[fieldName] = [];
        for (const file of value) {
          const url = await uploadFileToS3(this.supportingDocumentId, file);
          this.formData[fieldName].push(url);
          this.documentURL.push(url);
        }
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async getCashPayment(value, fieldName) {
      if (fieldName === 'cashRepaymentdocumentURL') {
        this.formData[fieldName] = [];
        for (const file of value) {
          const url = await uploadFileToS3(this.supportingDocumentId, file);
          this.formData[fieldName].push(url);
          this.documentURL.push(url);
        }
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    getSolicitorData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async getBuyingDetails(value, fieldName, index) {
      if (fieldName === 'documentURL') {
        this.formData.purchase_properties[index][fieldName] = [];
        for (const file of value) {
          const url = await uploadFileToS3(this.supportingDocumentId, file);
          this.formData.purchase_properties[index][fieldName].push(url);
          this.documentURL.push(url);
        }
      } else this.formData.purchase_properties[index][fieldName] = value;
    },
    async getSellingDetails(value, fieldName, index) {
      if (fieldName === 'documentURL') {
        this.formData.outgoingProperties[index][fieldName] = [];
        for (const file of value) {
          const url = await uploadFileToS3(this.supportingDocumentId, file);
          this.formData.outgoingProperties[index][fieldName].push(url);
          this.documentURL.push(url);
        }
      } else this.formData.outgoingProperties[index][fieldName] = value;
    },
    async saveData() {
      let payload = {};
      this.isLoading = true;
      payload.repaidBy = [];

      if (this.showPurchaseProperties) {
        payload.repaidBy.push('property_sold');
      } else {
        this.formData.purchase_properties = [];
        this.formData.outgoingProperties = [];
      }
      if (this.showLoanRefinancing) {
        payload.repaidBy.push('loan_refinanced');
      } else {
        this.formData.loanRefinancingAmount = 0;
        payload.loanRefinancingAmount = 0;
      }
      if (this.showCashPayment) {
        this.formData.repaidBy.push('cash_payment');
        payload.repaidBy.push('cash_payment');
      } else {
        this.formData.cashRepaymentAmount = 0;
        payload.cashRepaymentAmount = 0;
      }
      this.formData = {
        ...this.formData,
        applicationId: this.selectedLoanId,
        task_id: this.selectedTaskId,
      };
      payload = { ...this.formData, ...payload, taskId: this.taskId };
      if (!payload.cashRepaymentDate) {
        delete payload.cashRepaymentDate;
      }
      if (!payload.loanRefinancingDate) {
        delete payload.loanRefinancingDate;
      }
      payload.applicantId = this.profileDetails.id;
      try {
        await _post(TECHLEND_BE_URL + API.APPLICATION.CREATE_DISCHARGE_REQUEST, payload);

        await _patch(TECHLEND_BE_URL + API.TASKS.UPDATE_TASK + `?id=${this.selectedTaskId}`, {
          status: 'in_progress' || 'review',
          documentURL: this.documentURL,
        });
        this.$emit('completed');
        this.isLoading = false;
      } catch (err) {
        this.isLoading = true;
        if (err.response && err.response.data && err.response.data.error) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.error,
            life: 3000,
          });
        } else {
          console.log('Error', err.response);
          if (err.response.data == 'invalid-repayment-amount-error') {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Repayment amount should not be greater than the loan amount',
              life: 3000,
            });
          } else {
            await this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err?.response?.data?.message || 'Something went wrong',
              life: 3000,
            });
          }
        }
        this.isLoading = false;
      }
    },
    changePurchaseProperties() {
      let sellingDetails = this.sellingDetails.map((data) => {
        if (data.selected === true) {
          return true;
        } else {
          return false;
        }
      });
      if (!this.showPurchaseProperties) {
        this.formData.outgoingProperties = [
          {
            settlementDate: '',
            valuationPrice: 0,
            salePrice: 0,
            documentURL: [],
          },
        ];
      }
      if (sellingDetails[0] === false) {
        this.formData.outgoingProperties = [
          {
            settlementDate: '',
            valuationPrice: 0,
            salePrice: 0,
            documentURL: [],
          },
        ];
      }
    },
    changeLoanRefinancing() {
      if (!this.showLoanRefinancing) {
        this.formData.loanRefinancingAmount = '';
        this.formData.loanRefinancingDate = '';
        this.formData.loanRefinancingdocumentURL = [];
      }
    },
    changeCashPayment() {
      if (!this.showCashPayment) {
        this.formData.cashRepaymentAmount = '';
        this.formData.cashRepaymentdocumentURL = [];
        this.formData.cashRepaymentDate = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanId: 'loans/selectedLoanId',
      selectedTaskId: 'loans/selectedTaskId',
      profileDetails: 'settings/profileDetails',
    }),
    isSubmitRequestEnabled() {
      // one of the options needed to be selected
      return this.showPurchaseProperties || this.showLoanRefinancing || this.showCashPayment;
    },
  },
  async created() {
    this.isLoadingPage = true;
    if (this.selectedLoanDetails && this.selectedLoanDetails.outgoingProperties) {
      this.sellingDetails = this.selectedLoanDetails.outgoingProperties.map((obj) => {
        this.formData.outgoingProperties.push({
          property_id: obj.id,
          property: 'outgoing',
          address_1: obj.line1,
          address_2: obj.line2,
          valuationPrice: '',
          salePrice: '',
          documentURL: [],
        });
        return {
          ...obj,
          selected: true,
        };
      });
    }
    if (this.display) {
      const data = await this.getSolictorDetailsById();
      if (data && data.length) {
        const obj = data[0];
        this.formData.solicitorFirstName = obj.firstName;
        this.formData.solicitorLastName = obj.lastName;
        this.formData.solicitorEmail = obj.email;
        this.formData.solicitorPhone = obj.mobilePhoneNumber;
        this.formData.solicitorCompany = obj.company;
        this.formData.solicitorAddress = obj.address;
        this.formDataKey += 1;
      }
    }
    this.isLoadingPage = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-weight: bold;
  color: $primary-color;
  font-size: 2.25rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.modal-content {
  border: none;

  .section-1 {
    border: 1px solid rgba(2, 0, 56, 0.15);
    border-radius: 8px;

    .details-content {
      color: #020038;
      font-weight: 600;
      font-size: 20px;
    }
    .details-content-subtitle {
      color: #020038;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .label {
      margin-bottom: 1rem;
    }

    .mail-total {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
.discharge-details {
  color: #020038;
  font-weight: 600;
  font-size: 16px;
}
.col {
  padding: unset !important;
}
.acknowledgement {
  font-weight: 500;
  color: rgba(2, 0, 56, 0.3);
  font-size: 14px;
}
.settlement-date {
  font-family: Gordita-medium !important;
}
.signature-container {
  position: relative;
  canvas {
    background: white !important;
  }
  .clear {
    font-weight: 600;
    font-size: 18px;
    color: #54b7f9;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    .save {
      color: green;
    }
  }
}
@media (max-width: 992px) {
  .purchase-properties {
    flex-direction: column;
  }
}
</style>
