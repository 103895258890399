<template>
  <Card style="height: 380px" class="box-shadow" @click="goToBlog">
    <template #content>
      <div class="flex align-items-center flex-column">
        <div class="content-wrapper ml-3">
          <div class="mt-2">
            <img src="@/assets/images/blog.svg" />
          </div>
          <div class="title gr-bold mt-3">Bridgit blog</div>
          <div class="blog-content gr-regular mt-3">
            Keep yourself informed and stay up-to-date with the latest deals and news from Bridgit by visiting our blog!
          </div>
          <Button label="Visit our blog" iconPos="right" class="mr-top-10px mt-4" color="#020038" bgcolor="#a4bfe0" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';

export default {
  name: 'Blog',
  components: {
    Card,
  },
  methods: {
    goToBlog() {
      window.open('https://www.bridgit.com.au/blog');
    },
  },
};
</script>

<style scoped>
img {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
</style>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-size: 1.25rem;
}

.mr-top-10px {
  margin-top: 10px;
}

:deep(.p-card-body) {
  padding: 2rem 0 0 0 !important;
}

.blog-content {
  font-size: 0.875rem;
}

.content-wrapper {
  padding: 0 0.5rem;
}

.p-card {
  box-shadow: unset !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  font-family: $font-family !important;
  color: $white-color !important;
  background: #a4bfe0 !important;
  text-align: left !important;
  overflow: hidden;
  color: $secondary-color !important;
  @media (max-width: 992px) {
    margin: 10px;
  }
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  text-align: left !important;
  font-size: 0.875rem !important;
}
.blog-content {
  // max-width: 250px;
  // font-family: HK-medium;
  // margin: 0 auto;
}
</style>
